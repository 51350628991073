<template>
    <keep-alive>
        <component :is="dashboard"/>
    </keep-alive>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import { ACCOUNT_TYPE_ENTERPRISE, ACCOUNT_TYPE_PERSONAL, ACCOUNT_TYPE_FREE } from '@/constants';

export default {
    components: {
        DashboardFree: () => import('@/views/DashboardFree.vue'),
        DashboardPersonal: () => import('@/views/DashboardPersonal.vue'),
        DashboardEnterprise: () => import('@/views/DashboardEnterprise.vue'),
    },
    computed: {
        ...mapState({
            currentAccountId: (state) => state.accountId,
        }),
        ...mapGetters({
            currentAccount: 'account', // currently selected account object
        }),
        accountType() {
            return this.currentAccount ? this.currentAccount.type : ''; // TODO: return 'Error' instead of '' and make a DashboardError.vue  ?
        },
        dashboard() {
            switch (this.accountType) {
            case ACCOUNT_TYPE_FREE:
                return 'DashboardFree';
            case ACCOUNT_TYPE_PERSONAL:
                return 'DashboardPersonal';
            case ACCOUNT_TYPE_ENTERPRISE:
                return 'DashboardEnterprise';
            default:
                console.log('Dashboard.vue: error: unknown account type: "%s"', this.accountType);
                return 'DashboardFree';
            }
        },
        accountJson() {
            return JSON.stringify(this.currentAccount, null, 2);
        },
    },
    watch: {
        accountType() {
            console.log('dashboard.vue: accountType changed to: %s', this.accountType);
        },
        dashboard() {
            console.log('dashboard.vue: dashboard changed to: %s', this.dashboard);
        },
        currentAccount() {
            console.log('dashboard.vue: currentAccount changed to: %s', JSON.stringify(this.currentAccount, null, 2));
        },
    },
    mounted() {
        // TODO: stop tutorial when returned to dashboard
        // this.isTutor = false;
    },
    created() {
        if (this.$route.query.acct) {
            const queryAccountId = this.$route.query.acct;
            if (queryAccountId !== this.currentAccountId) {
                console.log(`dashboard.vue: setting current account to ${queryAccountId}`);
                this.$store.commit('setAccountId', queryAccountId);
            }
        }
    },
};
</script>
